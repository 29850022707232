<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row"><div class="col-lg-12"></div></div>
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="state.params.region" />
        <filter-province class="mr-2 mt-2" v-model:value="state.params.provinsi" v-model:region="state.params.region" />
        <filter-area class="mt-2" v-model:value="state.params.area" v-model:provinsi="state.params.provinsi" v-model:region="state.params.region" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="state.params.kabupaten"
          v-model:region="state.params.region"
          v-model:provinsi="state.params.provinsi"
          v-model:area="state.params.area"
        />
        <filter-brand class=" mr-2 mt-2" v-model:value="state.params.brand" />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="state.params.distributor"
          v-model:distributor="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledStartDate"
          v-model:value="state.params.start_date"
          placeholder=" Tanggal Awal"
          format="DD MMMM YYYY"
        />

        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledEndDate"
          v-model:value="state.params.end_date"
          placeholder=" Tanggal Akhir"
          format="DD MMMM YYYY"
        />

        <a-input-search
          class="mr-2 mt-2"
          v-model:value="state.params.q"
          placeholder="Cari ..."
          style="width: 300px"
        />

        <a-button class="mt-2" type="primary" title="cari" @click="fetchData" :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="laporan-detail-transaksi-distributor"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <md-table
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: state.meta.total,
                  pageSize: state.meta.per_page,
                  current: state.meta.page,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="state.isFetching"
              >
                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import apiClient from '@/services/axios'

import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterBrand,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref(null)

    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Transaksi',
          dataIndex: 'tanggal',
        },
        {
          title: 'ID Toko',
          dataIndex: 'customer_code',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'customer_name',
        },
        {
          title: 'Alamat Toko',
          dataIndex: 'customer_address',
        },
        {
          title: 'Kabupaten Toko',
          dataIndex: 'customer_kabupaten',
        },
        {
          title: 'Provinsi Toko',
          dataIndex: 'customer_propinsi',
        },
        {
          title: 'Regional Toko',
          dataIndex: 'customer_regional',
        },
        {
          title: 'Area Toko',
          dataIndex: 'customer_area',
        },

        {
          title: 'Brands',
          dataIndex: 'brand_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'Kode MD Royalty',
          dataIndex: 'kode_md_royalty',
        },
        {
          title: 'Nama MD Royalty',
          dataIndex: 'nama_md_royalty',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
          title: 'Zak Quantity ',
          dataIndex: 'quantity_zak',
        },
        {
          title: 'UOM 1',
          dataIndex: 'uom1',
        },
        {
          title: 'TON Quantity ',
          dataIndex: 'quantity_ton',
        },
        {
          title: 'UOM 2',
          dataIndex: 'uom2',
        },
        {
          title: 'No Transaksi',
          dataIndex: 'transaksi_code',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'distributor_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distributor_name',
        },
        {
          title: 'Kode Gudang ',
          dataIndex: 'warehouse_code',
        },
        {
          title: 'Nama Gudang ',
          dataIndex: 'warehouse_name',
        },
        {
          title: 'Regional Gudang',
          dataIndex: 'regional_name',
        },
        {
          title: 'Provinsi Gudang',
          dataIndex: 'warehouse_propinsi',
        },
        {
          title: 'Area Gudang',
          dataIndex: 'warehouse_area',
        },
        {
          title: 'Kabupaten Gudang',
          dataIndex: 'warehouse_kabupaten',
        },
      ],
      endpoint: '/api/report/detail-trx/distributor',
      data: [],
      loading: false,
      isFetching: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        brand: [],
        start_date: moment(new Date()).startOf('month'),
        end_date: moment(new Date()).subtract(1, 'days'),
        distributor: [],
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const disabledStartDate = (current) => {
        return current && current > moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
        return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
        state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
        state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data

          items.forEach((item, i) => {
            item.key = i
          })

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      handleTableChange,
      state,
      fetchData,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      queryParams,
    }
  },
})
</script>
